import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { translate as t } from 'utils/helpers';
import useLang from 'context/LanguageContext';
import styles from './Popup.module.scss';

const ConfirmationPopup = ({
  showConfirmation,
  message,
  confirmActionCallback,
  handleClose,
  submitButton,
  cancelButton,
  title,
  noNeedOfAlertIcon,
  submitButtonColor,
  disableSubmitButton,
}) => {
  const { lang } = useLang();
  return (
    <>
      <Modal
        className={`${styles.modalParent} ${styles.confirmPopup}`}
        show={showConfirmation}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body className={styles.modalBody}>
          <h4 className={styles.modalTitle}>
            {title || t(lang, 'common')('areYouSure')}
          </h4>
          {!noNeedOfAlertIcon && (
            <img
              className={styles.modalIcon}
              src="/other-icons/confirm-icon.svg"
              alt="Thanks"
            />
          )}
          <p className={styles.modalDescription}>{message}</p>
          <p><span className="info-icon">i</span> {t(lang,'common')('couponRemove')}</p>
          <div className={styles.confirmCTAs}>
            <Button
              className={`${styles.popupBtn} compact`}
              variant="outline-primary"
              onClick={handleClose}>
              {cancelButton || t(lang, 'common')('cancel')}
            </Button>
            <Button
              className={`${styles.popupBtn} compact`}
              variant={submitButtonColor ? null : 'danger'}
              onClick={confirmActionCallback}
              disabled={disableSubmitButton}
              style={{
                backgroundColor: submitButtonColor,
                color: submitButtonColor && '#fff',
                borderColor: submitButtonColor,
              }}>
              {submitButton || t(lang, 'common')('sure')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

ConfirmationPopup.propTypes = {
  showConfirmation: PropTypes.bool,
  message: PropTypes.string,
  confirmActionCallback: PropTypes.func,
  handleClose: PropTypes.func,
  submitButton: PropTypes.string,
  cancelButton: PropTypes.string,
  title: PropTypes.string,
  noNeedOfAlertIcon: PropTypes.bool,
  submitButtonColor: PropTypes.string,
  disableSubmitButton: PropTypes.func,
};

export default ConfirmationPopup;
